<template>
    <div :class="$style.onboard">
        <pt-icon
            :icon="icon"
            :icon-style="{
                width: '250px',
                height: '250px'
            }"
        ></pt-icon>
        <p>
            <slot></slot>
        </p>
        <router-link :to="{ name: toRouterName }">
            <pt-button size="medium" :class="$style.btn">{{ BtnText }}</pt-button>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'DataSettingOnboard',

    props: {
        icon: String,
        BtnText: String,
        toRouterName: String
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.onboard {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 80px;

    p {
        max-width: 450px;
        color: $pt-black-600;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        margin: 0px 0 40px;
    }

    .btn {
        width: 200px;
    }
}
</style>
