<template>
    <div :class="$style.table">
        <div :class="[$style.item, $style.itemHeader]">
            <div
                :class="[$style.itemContent, sortStates.index === $index && $style.itemActive]"
                v-for="(col, $index) in columns"
                :key="col.code"
                :span="col.span"
                @click="handleSortClick(col, $index)"
            >
                <template v-if="col.name">
                    <span>{{ col.name }}</span>
                    <el-tooltip v-if="col.tips">
                        <pt-icon
                            icon="pt-icon--help-new"
                            :icon-style="{
                                width: '16px',
                                height: '16px',
                                fill: '#5E6C84',
                                margin: '0 0 0 6px'
                            }"
                        ></pt-icon>
                        <div class="col_tips" slot="content" v-html="col.tips"></div>
                    </el-tooltip>
                    <div :class="[$style.sort, sortStates.index === $index && $style.colIsActive]">
                        <div
                            :class="[$style.sort_arrow, $style.arrow_asc, sortOptions.type === 'asc' && $style.active]"
                        ></div>
                        <div
                            :class="[
                                $style.sort_arrow,
                                $style.arrow_desc,
                                sortOptions.type === 'desc' && $style.active
                            ]"
                        ></div>
                    </div>
                    <!--  <div :class="$style.arrow" :sort="sortStates.type">
                        <pt-icon
                            icon="icon-sort-hover"
                            :icon-style="{
                                width: '12px',
                                height: '12px',
                                fill: '#5e6c84'
                            }"
                        ></pt-icon>
                    </div>-->
                </template>
            </div>
        </div>

        <div :class="$style.item" v-for="(row, $index) in currentRows" :key="row.rowId" :data-row-id="row.rowId">
            <template v-if="row.isRendered">
                <div
                    :class="$style.itemContent"
                    v-for="(col, $colIndex) in columns"
                    :key="$colIndex"
                    :span="getColSpan($colIndex)"
                >
                    <template v-if="row.rowEditing && col.canEdit">
                        <pt-input
                            v-if="col.code === 'description'"
                            :value="row[col.code + joinString]"
                            :autofocus="true"
                            @blur="event => handleIptBlur(event, col.code, $index)"
                        ></pt-input>
                        <el-select
                            v-else
                            size="small"
                            placeholder="$t('common.select_placeholder')"
                            :value="row[col.code + joinString]"
                            @change="val => handleTypeChange($index, val)"
                        >
                            <el-option
                                v-for="item in typeOptions"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"
                            ></el-option>
                        </el-select>
                    </template>
                    <span :title="row[col.sortCode]" v-else>{{ row[col.sortCode] }}</span>

                    <template v-if="$colIndex === columns.length - 1">
                        <div :class="$style.btnGroup" v-if="row.rowEditing">
                            <pt-button @click="handleSaveClick(row, $index)">{{ $t('common.button_save') }}</pt-button>
                            <pt-button type="text" color="black" @click="handleCancelClick(row, $index)">{{
                                $t('common.button_cancel')
                            }}</pt-button>
                        </div>
                        <el-dropdown
                            trigger="click"
                            v-else-if="moreOptions.length"
                            @command="command => handleCommand(row, $index, command)"
                        >
                            <span :class="$style.dropdownLink">
                                <pt-icon
                                    icon="pt-icon--more-dot"
                                    :icon-style="{
                                        width: '16px',
                                        height: '16px',
                                        fill: '#9099A7'
                                    }"
                                ></pt-icon>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    v-for="item in moreOptions"
                                    :key="item.code"
                                    :divided="item.divided"
                                    :command="item.code"
                                    >{{ item.name }}</el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import cloneUtils from '@/common/utils/clone.utils';
import domUtils from '@/common/utils/dom.utils';
import uuidUtils from '@common/utils/uuid.utils';
import commonUtils from '@/common/utils/common.utils';

export default {
    name: 'DataSettingsTable',

    props: {
        columns: Array,
        rows: Array,
        moreOptions: Array,
        typeOptions: Array,
        sortOptions: {
            type: Object,
            default: function() {
                return {
                    index: 0,
                    type: 'desc'
                };
            }
        }
    },

    data() {
        return {
            currentRows: [],
            joinString: '_tmp', //备份字段连接字符串
            sortStates: this.sortOptions
        };
    },

    computed: {
        canEditList() {
            return this.columns.filter(item => item.canEdit);
        }
    },

    created() {
        window.addEventListener('resize', this.handleResize, false);
        this.$eventBus.$on('mainScroll', this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize, false);
        this.$eventBus.$off('mainScroll', this.handleScroll);
    },

    mounted() {
        this.sort();
    },

    methods: {
        handleCommand(row, index, command) {
            if (command === 'edit') {
                this.updateTableBodyItem(index, 'rowEditing', true);
            }
        },

        handleTypeChange(index, val) {
            this.updateTableBodyItem(index, `type${this.joinString}`, val);
        },

        handleIptBlur(event, key, index) {
            const value = event.target.value;
            this.updateTableBodyItem(index, `${key}${this.joinString}`, value);
        },

        handleSaveClick(row, index) {
            this.$nextTick(() => {
                this.canEditList.forEach(item => {
                    const value = row[`${item.code}${this.joinString}`];
                    const sortCodeValue = this.getCellVal(item.code, value);
                    this.updateTableBodyItem(index, item.code, value);
                    this.updateTableBodyItem(index, item.sortCode, sortCodeValue);
                });
                this.updateTableBodyItem(index, 'rowEditing', false);
                this.$emit('change', row);
            });
        },

        handleCancelClick(row, index) {
            this.canEditList.forEach(item => {
                this.updateTableBodyItem(index, `${item.code}${this.joinString}`, row[item.code]);
            });
            this.updateTableBodyItem(index, 'rowEditing', false);
        },

        handleSortClick(col, index) {
            if (col.supportSortable) {
                this.sortStates.type = this.opposites(this.sortStates.type);
                // this.sortStates.index === index ? this.opposites(this.sortStates.type) : this.sortStates.type;
                this.sortStates.index = index;
                this.sort(index);
            }
        },

        getCellVal(code, value) {
            if (code === 'type') {
                const info = this.typeOptions.find(item => item.code === value.toLocaleUpperCase());
                return (info && info.name) || value;
            } else if (code === 'source') {
                return (value || [])
                    .map(i => {
                        var sourceInfo = this.sourceOptions.find(item => item.code === i);
                        return (sourceInfo && sourceInfo.name) || i;
                    })
                    .join();
            }
            return value;
        },

        getColSpan(colIndex) {
            const colHeader = this.columns[colIndex];
            return (colHeader && colHeader.span) || 3;
        },

        updateTableBodyItem(index, key, value) {
            this.$set(this.currentRows[index], key, value);
        },

        opposites(direction) {
            var mapper = { desc: 'asc', asc: 'desc' };
            return direction ? mapper[direction] : 'desc';
        },

        sort() {
            const comparators = {
                number: function(a, b, direction) {
                    if (a === b) return 0;
                    return direction > 0 ? a - b : b - a;
                },

                string: function(a, b, direction) {
                    if (a === b) return 0;
                    // null特殊对应，以免和空值一起排序时发生列表交替显示的情况
                    var comparison = direction > 0 ? b === null || a > b : a === null || a < b;
                    return comparison === false ? -1 : comparison - 0;
                }
            };
            const directionMapper = { asc: 1, desc: -1 };
            const header = this.columns[this.sortStates.index];
            const dataType = header.dataType.toLowerCase() || 'string';
            this.currentRows.sort((a, b) => {
                let comparator = comparators[dataType];
                return comparator.call(
                    null,
                    a[header.sortCode],
                    b[header.sortCode],
                    directionMapper[this.sortStates.type]
                );
            });
            this.renderCell();
        },

        renderCell() {
            this.currentRows &&
                this.$nextTick(() => {
                    this.currentRows.forEach((row, index) => {
                        const { isRendered, rowId } = row;
                        if (!isRendered) {
                            const $node = this.$el.querySelector(`[data-row-id="${rowId}"]`);
                            if (domUtils.domIsIn($node)) {
                                row.isRendered = true;
                                this.$set(this.currentRows[index], 'isRendered', true);
                            }
                        }
                    });
                });
        },

        handleResize() {
            this.renderCell();
        },

        handleScroll() {
            this.renderCell();
        }
    },

    watch: {
        rows: {
            handler: function(val) {
                this.currentRows = cloneUtils.deep(val).map(item => {
                    item.isRendered = false;
                    item.rowId = uuidUtils.uuid_8bit();
                    return item;
                });
                this.renderCell();
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';
:global {
    .col_tips {
        a {
            color: $pt-white;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.table {
    border: 1px solid $pt-black-40;
    border-radius: 2px;
    .item {
        font-size: 13px;
        display: flex;
        min-height: 56px;
        align-items: center;
        border-bottom: 1px solid $pt-black-40;
        background: $pt-white;
        &:not(.itemHeader) {
            &:hover {
                background-color: $pt-black-30;
                /*  .itemContent {
                    background-color: $pt-black-30;
                } */
            }

            .itemContent:last-child {
                justify-content: space-between;
            }
        }

        .itemContent {
            min-height: 56px;
            display: flex;
            align-items: center;
            color: $pt-black-600;

            padding: 18px 8px;
            line-height: 20px;
            word-break: break-all;
            &:first-child {
                padding-left: 24px;
            }
            &:hover {
                .sort {
                    &:not(.col-active) {
                        .arrow_desc {
                            border-color: $pt-black-300 transparent transparent transparent;
                        }
                    }
                    &.col-active {
                        .arrow_asc {
                            border-color: transparent transparent $pt-black-300 transparent;
                        }
                        .arrow_desc {
                            border-color: $pt-black-300 transparent transparent transparent;
                        }
                    }
                }
            }
            &.itemActive {
                .sort {
                    opacity: 1;
                    .active.arrow_asc {
                        border-color: transparent transparent $pt-green-60 transparent !important;
                    }
                    .active.arrow_desc {
                        border-color: $pt-green-60 transparent transparent transparent !important;
                    }
                }
            }

            &[span='16'] {
                width: 66.66666%;
            }
            &[span='12'] {
                width: 50%;
            }
            &[span='10'] {
                width: 41.66666%;
            }
            &[span='8'] {
                width: 33.33333%;
            }
            &[span='6'] {
                width: 25%;
            }
            &[span='4'] {
                width: 16.66667%;
            }
            &[span='3'] {
                width: 12.5%;
            }
            &[span='2'] {
                width: 8.33333%;
            }
            &[span='1'] {
                width: 4.16667%;
            }

            .btnGroup {
                display: flex;
                align-content: center;
                margin-left: 20px;

                button:first-child {
                    margin-right: 12px;
                }
            }

            .dropdownLink {
                width: 28px;
                height: 28px;
                display: inline-block;
                border-radius: 100%;
                border: 1px solid #d8d8d8;
                font-size: 0;
                line-height: 28px;
                text-align: center;
                vertical-align: middle;
                margin-top: 0;
                cursor: pointer;
                padding: 0;

                svg {
                    transform: rotate(90deg);
                    transform-origin: center;
                    vertical-align: middle;
                }
            }
        }
    }

    .itemHeader {
        cursor: pointer;
        user-select: none;
        background-color: $pt-black-10;
        border-bottom: 1px solid $pt-black-90;
        .itemContent:not(.itemActive):hover .sort {
            opacity: 1;
        }
        .itemContent:hover {
            background: $pt-black-30;
        }
        span {
            color: $pt-black-300;
            font-weight: 400;
            font-size: 13px;
            margin: 0 !important;
        }
        .sort {
            opacity: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            margin-left: 8px;
            height: 20px;
            .sort_arrow {
                border-width: 5px;
                border-style: solid;

                width: 10px;
                height: 10px;
                &.arrow_asc {
                    border-color: transparent transparent $pt-black-50 transparent;
                }
                &.arrow_desc {
                    border-color: $pt-black-50 transparent transparent transparent;
                    margin-top: 4px;
                }
            }
        }
        .arrow {
            margin-left: 6px;
            background-color: transparent;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            transition: all 0.3s;

            &[sort='asc'] {
                transform: rotate(180deg);
            }
        }
    }
}
</style>
